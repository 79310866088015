<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon *ngIf="!menuStyle?.logo">account_circle</mat-icon>
    <img *ngIf="menuStyle?.logo" style="border-radius: 50%;" [src]="menuStyle?.logo" alt="">
</button>
<mat-menu #menu="matMenu">
    <button *ngIf="!isMobile" mat-menu-item (click)="changeEvent('productos')"> 
        <mat-icon>dialpad</mat-icon>
        <span>Gestionar productos</span>
    </button>
    <button *ngIf="!isMobile" mat-menu-item (click)="changeEvent('perfil')">
        <mat-icon>contacts</mat-icon>
        <span>Gestionar perfil</span>
    </button>
    <button *ngIf="!isMobile" mat-menu-item (click)="changeEvent('qr-generator')">
        <mat-icon>qr_code</mat-icon>
        <span>Generar QR</span>
    </button>
    <button mat-menu-item (click)="logout()" >
        <mat-icon>logout</mat-icon>
        <span>Cerrar sesión</span>
    </button>
</mat-menu>

<!-- https://materialui.co/icons -->