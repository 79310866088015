import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Seccion } from 'src/app/interfaces/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  seccion: any;

  constructor(private route: ActivatedRoute) { 
    const id_cliente = this.route.snapshot.paramMap.get('id'); 
    if(Number(id_cliente) > 0){
      environment.id_cliente = Number(id_cliente);
    }
  }

  ngOnInit(): void {
  }

  getSeccion(event: Seccion){
      this.seccion = event;
      // console.log(this.seccion);

      // const menuDiv = document.getElementById(this.seccion.categoria);
      // if(menuDiv){
      //   menuDiv.scrollIntoView({
      //     behavior: "smooth",
      //     // behavior: "auto",
      //     block: "start",
      //     inline: "nearest"
      //   });
      // }
      
  }

}
