<notifier-container></notifier-container>
<!-- <div class="container" *ngIf="isMobile" class="cuerpoWeb pt-5">
    <div class="row justify-content-center align-items-center minh-100">
        <div class="p-4 card opacity-75">
            <h1 class="text-center mt-2">¡ Bienvenido !</h1>
            <h3 class="text-center">La opción móvil aún no está dispoinible, pero puedes gestionar tu carta más comodamente desde tu ordenador!</h3>
        </div>
    </div>
</div> -->
<div class="auth-wrapper d-flex no-block justify-content-center align-items-center cuerpoWeb">
    <div class="auth-box m-3 m-md-0 animated fadeIn">
        <div>
            <div style="min-width: 400px;">
                <div class="row">
                    <div class="col-sm-12" *ngIf="!recoverPassword">
                        <div class="md-float-material form-material">
                            <div class="auth-box card">
                                <div class="float-right">
                                    <button type="button" (click)="login = false;" [disabled]="!login"
                                        class="btn btn-success float-right m-2">Regístrate</button>
                                    <button type="button" (click)="login = true;" [disabled]="login"
                                        class="btn btn-primary float-right m-2">Ingresa</button>
                                </div>
                                <div class="line-separator"></div>
                                <div class="card-block animated fadeIn" *ngIf="!login">
                                    <form [formGroup]="loginFormRegistro" novalidate (ngSubmit)="registrarse()">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h3 class="text-center heading">Crea tu cuenta. Te tomará un minuto </h3>
                                            </div>
                                        </div>
    
                                        <div class="mt-3 form-group form-primary">
                                            <input type="text" class="form-control" formControlName="email"  placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && fr.email.errors }">
                                            <div *ngIf="submitted && fr.email.errors" class="invalid-feedback">
                                                <div *ngIf="fr.email.errors.required">El email es requerido</div>
                                                <div *ngIf="fr.email.errors.email">Debe ingresar un email válido</div>
                                            </div>
                                        </div>
    
                                        <div class="form-group form-primary">
                                            <input type="password" class="form-control" formControlName="password" placeholder="Contraseña"
                                                [ngClass]="{ 'is-invalid': submitted && fr.password.errors }">
                                            <div *ngIf="submitted && fr.password.errors" class="invalid-feedback">
                                                <div *ngIf="fr.password.errors.required">La contraseña es requerida</div>
                                                <div *ngIf="fr.password.errors.minlength">La contraseña debe tener mínimo 8 carcateres</div>
                                            </div>
                                        </div>
                                        <div class="form-group form-primary">
                                            <input type="password" class="form-control" formControlName="password2" placeholder="Repite contraseña"
                                                [ngClass]="{ 'is-invalid': submitted && fr.password2.errors }">
                                            <div *ngIf="submitted && fr.password2.errors" class="invalid-feedback">
                                                <div *ngIf="fr.password2.errors.required">La contraseña es requerida</div>
                                                <div *ngIf="fr.password2.errors.minlength">La contraseña debe tener mínimo 8 carcateres</div>
                                            </div>
                                        </div>
    
                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="submit" 
                                                    class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">
                                                    Regístrate ahora
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    

                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">o</div>
                                        <div class="line-separator"></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <a class="btn btn-lg btn-google btn-block text-uppercase btn-outline"
                                                href="#"><img
                                                    src="https://img.icons8.com/color/16/000000/google-logo.png">
                                                Registrarse con google</a>

                                        </div>
                                    </div>
                                    <br>

                                    <p (click)='login = true;' class="text-inverse text-center cursor-pointer">Ya tienes
                                        una cuenta? Login</p>
                                </div>
                                <div class="card-block animated fadeIn" *ngIf="login">
                                    <form [formGroup]="loginFormAcceso" novalidate (ngSubmit)="logIn()">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h3 class="text-center heading">Login</h3>
                                            </div>
                                        </div>
    
                                        <div class="mt-3 form-group form-primary">
                                            <input type="text" class="form-control" formControlName="email"  placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">El email es requerido</div>
                                                <div *ngIf="f.email.errors.email">Debe ingresar un email válido</div>
                                            </div>
                                        </div>
    
                                        <div class="form-group form-primary">
                                            <input type="password" class="form-control" formControlName="password" placeholder="Contraseña"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">La contraseña es requerida</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="submit" 
                                                    class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">
                                                    Ingresa ahora
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    

                                    

                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">o</div>
                                        <div class="line-separator"></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <a (click)="firebaseService.GoogleAuth()"
                                                class="btn btn-lg btn-google btn-block text-uppercase btn-outline"
                                                href="#"><img
                                                    src="https://img.icons8.com/color/16/000000/google-logo.png">
                                                Ingresar con Google</a>

                                        </div>
                                    </div>
                                    <br>

                                    <p (click)="recoverPassword = true" class="text-inverse text-center">Has olvidado tu contraseña? Haz click aquí</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" *ngIf="recoverPassword">
                        <div class="md-float-material form-material">
                            <div class="auth-box card">
                                <div class="float-right">
                                    <button type="button" (click)="recoverPassword = false;"
                                        class="btn btn-success float-right m-2">Volver</button>
                                </div>
                                <div class="card-block animated fadeIn">
                                    <form [formGroup]="loginFormRecover" novalidate (ngSubmit)="recoverPasswordSubmit()">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h3 class="text-center heading">Recuperar constraseña</h3>
                                            </div>
                                        </div>

                                        <div class="mt-3 form-group form-primary">
                                            <input type="text" class="form-control" formControlName="email"  placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && r.email.errors }">
                                            <div *ngIf="submitted && r.email.errors" class="invalid-feedback">
                                                <div *ngIf="r.email.errors.required">El email es requerido</div>
                                                <div *ngIf="r.email.errors.email">Debe ingresar un email válido</div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="submit" 
                                                    class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">
                                                    Enviar correo de recuperación
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>