import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Seccion } from 'src/app/interfaces/interfaces';

import { TablasService } from 'src/app/services/tablas.service';
import { environment } from 'src/environments/environment';

import { MenuStylesService } from 'src/app/services/menu-styles.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Output() comunicarSeccion = new EventEmitter();
  menuStyle: any;
  // JSON de prueba
  JSON_menu: Seccion[] = [
    {
      id : 1,
      categoria : 'Bañeras',
      orden : 1,
      submenu : [
        {
          id : 22,
          categoria : 'Ferrum',
          orden : 1,
          submenu : [
            {
              id : 222,
              categoria : 'Accesorios',
              orden : 1
            },
            {
              id : 232,
              categoria : 'Pelotudeces',
              orden : 2
            }]
        },
        {
          id : 23,
          categoria : 'Roca',
          orden : 2
        },
        {
          id : 24,
          categoria : 'Pirulito',
          orden : 3
        }
      ]
    },
    {
      id : 2,
      categoria : 'Sanitarios',
      orden : 2
    },
    {
      id : 3,
      categoria : 'Griferias',
      orden : 3,
      submenu : []
    }
  ];
  

  constructor(private tablasService: TablasService, private styleMenuService: MenuStylesService) {
    this.getMenu();
   }

  ngOnInit(): void {
    this.styleMenuService.castStyleMenu.subscribe(menuStyle => this.menuStyle = menuStyle);
  }


  formatearMenu(){
    // const inicio = {id : 0,categoria : 'Inicio',orden : 0}

    // this.JSON_menu.unshift(inicio);

    // const contacto = {id : 0,categoria : 'Contacto',orden : 999}
    // this.JSON_menu.push(contacto);
  }

  enviarSeccion(seccion: Seccion){
    this.comunicarSeccion.emit(seccion);
  }

  getMenu(){
    const body = {
      'accion' : 'get',
      'tabla':'cliente',     
      'select':['menu'],
      'where' :[{'campo':'id','tipo':'n','condicion':'=','valor': ''+environment.id_cliente}]
    }
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      this.JSON_menu = JSON.parse(data[0].menu);
      this.formatearMenu();
    })
    .catch((error) => {
       console.error('Error al obtener datos:', error);
    });
  }



}
