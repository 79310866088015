import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MenuStylesService } from 'src/app/services/menu-styles.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  @Output() apartadoEvent = new EventEmitter<string>();
  isMobile: boolean = false;
  menuStyle: any;
  constructor(private deviceService: DeviceDetectorService, private styleMenuService: MenuStylesService, private fireBase: FirebaseService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.styleMenuService.castStyleMenu.subscribe(menuStyle => this.menuStyle = menuStyle);
  }

  logout(){
    // sessionStorage.removeItem('token');
    // sessionStorage.removeItem('id_cliente');
    // this.router.navigate(['/login']);
    this.fireBase.Logout();
  }

  changeEvent(apartado: string){
    this.apartadoEvent.emit(apartado);
  }


}
