import { Component, Input, OnChanges, OnInit, LOCALE_ID, Inject } from '@angular/core';
 
import { getLocaleCurrencySymbol }  
  from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { FontService } from 'src/app/services/font.service';
import { GetMenuServiceService } from 'src/app/services/get-menu-service.service';
import { MenuStylesService } from 'src/app/services/menu-styles.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-listado-menu',
  templateUrl: './listado-menu.component.html',
  styleUrls: ['./listado-menu.component.scss']
})
export class ListadoMenuComponent implements OnInit, OnChanges {
  @Input() seccion: any;
  sym = getLocaleCurrencySymbol(this.locale); 
  menu: any;
  menuAux: any;
  filterMenu: any;
  menuStyle: any;
  hostDir = `${environment.host}/`;
  nombreProducto: string = '';
  menuReady: boolean = false;

  alergenos = [
    {
      nombre: 'Gluten',
      cod: 'ce', //g
      img: '../../../assets/alergenos/g.png',
      checked: false
    },
    {
      nombre: 'Huevos',
      cod: 'hu', //h
      img: '../../../assets/alergenos/h.png',
      checked: false
    },
    {
      nombre: 'Pescado',
      cod: 'pe', //pe
      img: '../../../assets/alergenos/p.png',
      checked: false
    },
    {
      nombre: 'Cacahuetes',
      cod: 'ca', //cc
      img: '../../../assets/alergenos/cc.png',
      checked: false
    },
    {
      nombre: 'Soja',
      cod: 'so', //s
      img: '../../../assets/alergenos/s.png',
      checked: false
    },
    {
      nombre: 'Lacteos',
      cod: 'la', //l
      img: '../../../assets/alergenos/l.png',
      checked: false
    },
    {
      nombre: 'Frutos secos',
      cod: 'fs', //fc
      img: '../../../assets/alergenos/fc.png',
      checked: false
    },
    {
      nombre: 'Apio',
      cod: 'ap', //a
      img: '../../../assets/alergenos/a.png',
      checked: false
    },
    {
      nombre: 'Mostaza',
      cod: 'mos', //m
      img: '../../../assets/alergenos/m.png',
      checked: false
    },
    {
      nombre: 'Granos de sésamo',
      cod: 'se', //gs
      img: '../../../assets/alergenos/gs.png',
      checked: false
    },
    {
      nombre: 'Altramuses',
      cod: 'al',
      img: '../../../assets/alergenos/al1.png',
      checked: false
    },
    {
      nombre: 'Crustáceos',
      cod: 'cr',
      img: '../../../assets/alergenos/c1.png',
      checked: false
    },
    {
      nombre: 'Molúscos',
      cod: 'mo',
      img: '../../../assets/alergenos/mo1.png',
      checked: false
    },
    {
      nombre: 'Sulfitos',
      cod: 'su', //gs
      img: '../../../assets/alergenos/d1.png',
      checked: false
    },

  ];


  constructor(private fontService: FontService, public getMenuService: GetMenuServiceService, 
    private styleMenuService: MenuStylesService, public dialog: MatDialog, private spinner: NgxSpinnerService,
    @Inject(LOCALE_ID) public locale: string) {
    }

  ngOnChanges(): void {
    // console.log(this.seccion);
    this.menu = this.menuAux;
    if(this.seccion?.categoria){
      this.spinner.show();
      setTimeout(() => {
        const menuDiv = document.getElementById(this.seccion.categoria);
        if(menuDiv){
          menuDiv.scrollIntoView({
            behavior: "smooth",
            // behavior: "auto",
            block: "start",
            inline: "nearest"
          });
        }
        this.spinner.hide();
      }, 1000);
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    const id_cliente = environment.id_cliente;
    this.getMenuService.getMenuById(id_cliente).subscribe(data => {
      this.menu = data;
      this.menuAux = data;
    });
    this.styleMenuService.castStyleMenu.subscribe(menuStyle => {
      this.menuStyle = menuStyle;
    });
    this.fontService.loadFont(this.menuStyle.textFont);
    setTimeout(() => {
      this.spinner.hide();
      this.menuReady = true;
    }, 3000);
  }

  selectedItem?: any;
  openDialog(modal:any, item?: any) {
    this.selectedItem = item;

    const dialogRef = this.dialog.open(modal, {
      width: '90%'
    });
  }

  searchInMenuService(name: string = ''){
    const id_cliente = environment.id_cliente;
    this.spinner.show();
    this.getMenuService.getMenuById(id_cliente, name).subscribe(data => {
      this.spinner.hide();
      this.menu = data;
      this.nombreProducto = '';
      this.dialog.closeAll();
    });
  }
  
}
