<div class="listado row">

        <div class="col-md-4 m-auto" *ngFor="let item of alergenos; let i = index">
            <div class="producto_box">
                <div class="contenedor_img" [ngClass]="item.checked ? 'border_checked' : ''" (click)="changeCheck(i)">
                    <img class="producto_img"  ngSrc="{{item.img}}" width="125" height="125" *ngIf="item.img"[alt]="item.nombre">
                    <img class="producto_img" width="100" height="100" *ngIf="!item.img" ngSrc="imageError" [alt]="item.nombre">
                </div>
                <h3 *ngIf="item.nombre" class="producto_title">{{item.nombre}}</h3>
            </div>
        </div>

</div>