import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TablasService } from './tablas.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class MenuStylesService {
  private readonly notifier: NotifierService;
  constructor(private tablasService: TablasService, private http: HttpClient, notifierService: NotifierService) { 
    this.notifier = notifierService;

    // if(localStorage.getItem('user')){
    //   const token = localStorage.getItem('user');
    //   const user = JSON.parse(token as string);
    //   console.log(user);
    //   environment.email = user?.email || '';
  
    //   // this.getConfigCarta(user?.email);
    //   this.getConfigCarta();
    // }
    if(environment.id_cliente){
      this.getConfigCarta();
    }
  }

  defaultMenuStyle = {
    nombreEmpresa: '',
    direccionEmpresa: '',
    provinciaEmpresa: '',
    cpEmpresa: null,
    horariosEmpresa: '',
    instagramEmpresa: '',
    facebookEmpresa: '',
    logo: '../assets/img/logo.png',
    backgroundLogo: '../assets/img/encabezado.png',
    bgBarraSuperior: '#2D2C2D',
    bgMenu: '#fff2d5',
    colorTexto1 : '#a77b00',
    colorTexto2 : '#2D2C2D',
    colorDetalles : '#ffb900',
    textFont : { header: 'Josefin Sans', body: 'Amatic SC'}
  }

  private user = new BehaviorSubject<any>(this.defaultMenuStyle);
  castStyleMenu = this.user.asObservable();
  
  editMenu(newStyleMenu: any){
    this.user.next(newStyleMenu); 
  }

  updateConfigMenu(){
    let body = {
      "accion" : "put_config_menu",
      "id_cliente": Number(environment.id_cliente),     
      "config_menu": this.defaultMenuStyle
    };
    this.tablasService.PostDataFromAPI("functions", body).then( data => {
      if(data && data.cod === '0'){
        this.notifier.notify('success', 'Perfil actualizado con éxito');
      }else{
        this.notifier.notify('error', 'Error al intentar actualizar el perfil');
      }
    },(err)=>{

    });
  }

  getConfigCarta(){
    let body = {
      "accion" : "get_config_menu",
      "usuario": environment.id_cliente
    };
    const headers = {'Content-Type':  'application/json'};
    const ruta = `${environment.api}/get`;
    this.http.post<any>(ruta, body, { headers }).subscribe((data:any) =>  {
      if(data.cod == 0){
        const datos = data.datos;
        this.defaultMenuStyle = {
          nombreEmpresa: datos.nombreEmpresa || '',
          direccionEmpresa: datos.direccionEmpresa || '',
          provinciaEmpresa: datos.provinciaEmpresa || '',
          cpEmpresa: datos.cpEmpresa || '',
          horariosEmpresa: datos.horariosEmpresa || '',
          instagramEmpresa: datos.instagramEmpresa || '',
          facebookEmpresa: datos.facebookEmpresa || '',
          logo: datos.logo? datos.logo : '',
          backgroundLogo: datos.backgroundLogo? datos.backgroundLogo : '',
          bgBarraSuperior: datos.bgBarraSuperior || '',
          bgMenu: datos.bgMenu || '',
          colorTexto1 : datos.colorTexto1 || '',
          colorTexto2 : datos.colorTexto2 || '',
          colorDetalles : datos.colorDetalles || '',
          textFont : datos.textFont || ''
        }
        this.editMenu(this.defaultMenuStyle);
      }
    });
  }

}
