<div style="text-align: right;">
    <button mat-button [disabled]="changes <= 1" (click)="openDialog(editItemConfirmation)">
        <mat-icon class="d-inline">edit</mat-icon><p class="pt-2 d-inline"> EDITAR</p>
    </button>
</div>
 <div style="width: 800px">

    <mat-horizontal-stepper orientation="horizontal" [linear]="isLinear" #stepper style="min-height: 400px;">
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Datos del producto</ng-template>
          <mat-form-field class="form-field">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="nombre" required>
          </mat-form-field><br>
          <mat-form-field class="form-field">
            <mat-label>Código</mat-label>
            <input matInput placeholder="Código" formControlName="codigo">
          </mat-form-field><br>
          <mat-form-field class="form-field">
            <mat-label>Descripción</mat-label>
            <textarea matInput rows="4" placeholder="Ej. medidas" formControlName="descripcion"></textarea>
          </mat-form-field><br>
          <mat-form-field>
            <mat-label>Precio</mat-label>
            <input type="number" min="0" value="0" step="0.1" matInput placeholder="$" formControlName="precio">
          </mat-form-field>
          <mat-form-field class="ml-3">
            <mat-label>Stock</mat-label>
            <input type="number" min="0" value="0" step="any" matInput placeholder="Cantidad" formControlName="stock">
          </mat-form-field><br>
          <div class="float-right mt-2">
            <button mat-button matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step><mat-slide-toggle (click)="$event.stopPropagation()" class="float-left pt-2 pl-2">Visible</mat-slide-toggle>
      <mat-step [stepControl]="secondFormGroup">
        <!-- <form [formGroup]="secondFormGroup"> -->
        <form>
          <ng-template matStepLabel>Imagen</ng-template>
            <div class="mt-3">
              <mat-dialog-content>
                <app-image-cropper (imageEvent)="receiveImage($event)" ></app-image-cropper>
              </mat-dialog-content>
            </div>
            <div  class="m-auto mt-3" style="width: 400px; height: 400px;" *ngIf="showOriginalImage">
                    <img class="m-auto w-100" [src]="newProductToChild.imagen ? hostDir + newProductToChild.imagen : imageError">
            </div>
            <div class="float-right">
                <button mat-button matStepperPrevious>Atrás</button>
            </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Alergenos</ng-template>
        <app-alergenos [alergenosFromFather]="newProductToChild" (alergenosEvent)="receiveAlergenos($event)"></app-alergenos>
      </mat-step>
    </mat-horizontal-stepper>
      
</div>

<ng-template #editItemConfirmation>
    <div *ngIf="true">
      <h1 mat-dialog-title>Editar '{{this.firstFormGroup.controls['nombre'].value}}'</h1>
      <div mat-dialog-content>
        Realmente desea editar el producto?
      </div>
      <div mat-dialog-actions class="float-right">
        <button mat-button mat-dialog-close>Cancelar</button>
        <button mat-button mat-dialog-close cdkFocusInitial  (click)="editProduct();">Aceptar</button>
      </div>
    </div>
</ng-template>