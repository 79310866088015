import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuStylesService } from 'src/app/services/menu-styles.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  menuStyle: any;
  showOlderLogo: boolean = true;

  isLinear = false;
  firstFormGroup = this._formBuilder.group({
    nombre: ['', Validators.required],
    direccion: [''],
    provincia: [''],
    cp: [''],
    apertura: [''],
    instagram: [''],
    facebook: [''],
  });

  secondFormGroup = this._formBuilder.group({
    imagen: ['', Validators.required],
  });
  constructor(private _formBuilder: UntypedFormBuilder, private styleMenuService: MenuStylesService, public dialog: MatDialog) { }
  ngAfterViewInit(): void {
    this.firstFormGroup.setValue({
      nombre: this.menuStyle.nombreEmpresa, 
      direccion: this.menuStyle.direccionEmpresa,
      provincia: this.menuStyle.provinciaEmpresa,
      cp: this.menuStyle.cpEmpresa,
      apertura: this.menuStyle.horariosEmpresa,
      instagram: this.menuStyle.instagramEmpresa,
      facebook: this.menuStyle.facebookEmpresa
    });
  }

  ngOnInit(): void {
    this.styleMenuService.castStyleMenu.subscribe(menuStyle => this.menuStyle = menuStyle);
  }

  

  receiveImage(event: string){
    this.menuStyle.logo = event;
    this.showOlderLogo = false;
    this.emitMenu();
  }

  getFirstFormGroupValues(){
    this.menuStyle.nombreEmpresa = this.firstFormGroup.controls['nombre'].value;
    this.menuStyle.direccionEmpresa = this.firstFormGroup.controls['direccion'].value;
    this.menuStyle.provinciaEmpresa = this.firstFormGroup.controls['provincia'].value;
    this.menuStyle.cpEmpresa = this.firstFormGroup.controls['cp'].value;
    this.menuStyle.horariosEmpresa = this.firstFormGroup.controls['apertura'].value;
    this.menuStyle.instagramEmpresa = this.firstFormGroup.controls['instagram'].value;
    this.menuStyle.facebookEmpresa = this.firstFormGroup.controls['facebook'].value;
  }

  emitMenu(){
    this.styleMenuService.editMenu(this.menuStyle);
  }

  openDialog(modal: any) {
    if(modal){
      const dialogRef = this.dialog.open(modal, {
        // width: '50%'
     });
     dialogRef.afterClosed().subscribe(result => {
      //  console.log(`Dialog result: ${result}`);
     });
    }
  }


  saveMenu(){
    this.styleMenuService.updateConfigMenu();
  }

}
