<div class="content">

  <!-- menu -->
  <div>
    <div class="contenedor-menu" [ngStyle]="{'background-color': menuStyle.bgBarraSuperior}">
      <button mat-button [matMenuTriggerFor]="menuPrincipal">MENU</button>
      <mat-menu #menuPrincipal="matMenu">
        <ng-container *ngFor="let item of JSON_menu">
          <button mat-menu-item *ngIf="item.submenu && item.submenu.length > 0"
            [matMenuTriggerFor]="submenu">{{item.categoria.toUpperCase()}}</button>
          <button mat-menu-item *ngIf="!item.submenu || !item.submenu.length">{{item.categoria.toUpperCase()}}</button>
          <ng-container>
            <mat-menu #submenu="matMenu">
              <button *ngFor="let subItem of item.submenu" mat-menu-item>{{subItem.categoria.toUpperCase()}}</button>
            </mat-menu>
          </ng-container>
        </ng-container>
      </mat-menu>
      <div class="float-right" style="display: inline;">
        <p class="facebook-icon">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
            <path fill="currentColor" fill-rule="evenodd"
              d="M18.896 0H1.104C.494 0 0 .494 0 1.104v17.792C0 19.506.494 20 1.104 20h9.578v-7.745H8.076V9.237h2.606V7.01c0-2.584 1.578-3.99 3.883-3.99c1.104 0 2.052.082 2.329.119v2.7h-1.598c-1.254 0-1.496.596-1.496 1.47v1.927h2.989l-.39 3.018h-2.6V20h5.097c.61 0 1.104-.494 1.104-1.104V1.104C20 .494 19.506 0 18.896 0Z" />
          </svg>
        </p>
        <p class="instagram-icon">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z" />
          </svg>
        </p>
      </div>
    </div>

  </div>

  <!-- CARTA -->

  <div [ngStyle]="{'background-color': menuStyle.bgMenu}">
    <header>
      <div class="encabezado" [ngStyle]="{'background-image':'url('+ menuStyle.backgroundLogo +')'}">
        <!-- [ngStyle]="{'background-image':'url(;'+ menuStyle.backgroundLogo +')'}" -->
        <div class="logo">
          <img class="imgLogo" [src]="menuStyle.logo" width="100%" style="border-radius: 50%;">
        </div>
      </div>
    </header>

    <!-- ------------------------------------------------- -->

    <section class="menu pb-3" style="margin-top: 100px;">

      <div>
        <h2 class="tituloMenu" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto1}" > BIENVENIDOS </h2>
      </div>

      <hr class="linea" [ngStyle]="{'border-top': '2px solid ' + menuStyle.colorDetalles}">

      <!-- ------------------------------------------------- -->
      <div class="">
        <div class="cuadros">
          <div class="titulo" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto1}" >ENTRANTES PARA COMPARTIR</div>

          <div class="row">
            <div class="col-md-10 col-sm-12 pl-3">
              <div class="col-sm-12 nombre" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto2}">Nombre</div>
              <div class="col-sm-12 descripcion" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto2}">Descripcion<p class="parrafoMuerto"> </p>
                <hr class="lineaPrecio">
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="divPrecio" [ngStyle]="{'background-color': menuStyle.colorDetalles}">
                <p class="estilosPrecio" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto2}">40€</p>
              </div>
            </div>
            <div class="col-12">
              <div class="ml-2">
                <img style="width: 25px;display: inline; padding-left: 5px;" src="../../../../assets/alergenos/a.png"
                  alt="">
                <img style="width: 25px;display: inline; padding-left: 5px;" src="../../../../assets/alergenos/c1.png"
                  alt="">
                <img style="width: 25px;display: inline; padding-left: 5px;" src="../../../../assets/alergenos/fc.png"
                  alt="">
                <img style="width: 25px;display: inline; padding-left: 5px;" src="../../../../assets/alergenos/g.png"
                  alt="">
                <img style="width: 25px;display: inline; padding-left: 5px;" src="../../../../assets/alergenos/h.png"
                  alt="">
                <img style="width: 25px;display: inline; padding-left: 5px;" src="../../../../assets/alergenos/m.png"
                  alt="">
                <img style="width: 25px;display: inline; padding-left: 5px;" src="../../../../assets/alergenos/fc.png"
                  alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="linea" [ngStyle]="{'border-top': '2px solid ' + menuStyle.colorDetalles}">

      <!-- ------------------------------------------------- -->

    </section>
  </div>



</div>