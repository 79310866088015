import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TablasService {
  static PostDataFromAPI: any;
  constructor(private http: HttpClient, private firebaseService: FirebaseService) { }

  PostDataFromAPI(endpoint:string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const urlaux = `${environment.api}/${endpoint}`;
      const authorization = this.getBearer();
      let headers;
      
      headers = {Authorization: authorization, 'Content-Type':  'application/json'};
      
      this.http.post<any>(urlaux, body , { headers }).subscribe((data) => {
        if(data.cod === 1 && data.msj === 'Invalid Token'){
          // this.firebaseService.Logout();
        }
        resolve(data.datos);
      },
      (error) => {
        reject(error);
      });
    });

  }


  // desde que hay firebase no se necesita
  getBearer = () => {
    if (typeof sessionStorage !== 'undefined') {
      // Recupera el valor almacenado en sessionStorage.
      const storedBase64 = localStorage.getItem('token');
      // Asigna el valor recuperado a la variable base64String.
      if(storedBase64){
        return atob(storedBase64); 
      }else{
        return '';
      }
    } else {
      console.error('El localStorage no está disponible en este navegador.');
      return '';
    }
  }

}
