
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="contenedor pb-5">
    <h1 style="text-align: center;" class="mb-4">
        <span class="title">{{titulo}}</span>
    </h1>
    <div class="listado">
        <div class="row m-auto align-center" *ngFor="let item of JSON_categorias">
            <div class="col-md-12 etiqueta"> 
                {{item.categoria}}
            </div>
            <div class="galeria m-auto" *ngFor="let subitem of item.productos">
                <div class="producto_box">
                    <div class="contenedor_img">
                        <!-- <ng-magnizoom class="producto_img" *ngIf="subitem.imagen"
                            [imageStyle]="{ 'max-width': '300px', 'max-height': '300px', 'display': 'block', 'margin': '0 auto'}"
                            zoomMode="COVER"
                            imageSrc="{{hostDir + subitem.imagen}}">
                        </ng-magnizoom> -->
                        <img class="producto_img"  ngSrc="{{hostDir + subitem.imagen}}" style="position: relative;object-fit: contain;max-width: 300px;max-height: 300px;" 
                            fill *ngIf="subitem.imagen"[alt]="subitem.nombreproducto">

                        <img class="producto_img" width="300" height="300" *ngIf="!subitem.imagen" ngSrc="imageError" [alt]="subitem.nombreproducto">
                    </div>
                    <h3 *ngIf="subitem.nombreproducto" class="producto_title">{{subitem.nombreproducto}}</h3>
                    <p *ngIf="subitem.codigo" class="producto_codigo">{{subitem.codigo}}</p>
                    <!-- <p *ngIf="subitem.descripcion" class="producto_description">{{subitem.descripcion}}</p> -->
                    <div [innerHTML]="subitem.descripcion" style="white-space: pre-line; text-align: center;"></div>
                </div>
            </div>
        </div>


    </div>

    
</div>