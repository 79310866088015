import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alergenos',
  templateUrl: './alergenos.component.html',
  styleUrls: ['./alergenos.component.scss']
})
export class AlergenosComponent implements OnInit {
  @Output() alergenosEvent = new EventEmitter<any>();
  @Input() alergenosFromFather: any; // object | undefined;
  alergenos = [
    {
      nombre: 'Gluten',
      cod: 'ce', //g
      img: '../../../assets/alergenos/g.png',
      checked: false
    },
    {
      nombre: 'Huevos',
      cod: 'hu', //h
      img: '../../../assets/alergenos/h.png',
      checked: false
    },
    {
      nombre: 'Pescado',
      cod: 'pe', //pe
      img: '../../../assets/alergenos/p.png',
      checked: false
    },
    {
      nombre: 'Cacahuetes',
      cod: 'ca', //cc
      img: '../../../assets/alergenos/cc.png',
      checked: false
    },
    {
      nombre: 'Soja',
      cod: 'so', //s
      img: '../../../assets/alergenos/s.png',
      checked: false
    },
    {
      nombre: 'Lacteos',
      cod: 'la', //l
      img: '../../../assets/alergenos/l.png',
      checked: false
    },
    {
      nombre: 'Frutos secos',
      cod: 'fs', //fc
      img: '../../../assets/alergenos/fc.png',
      checked: false
    },
    {
      nombre: 'Apio',
      cod: 'ap', //a
      img: '../../../assets/alergenos/a.png',
      checked: false
    },
    {
      nombre: 'Mostaza',
      cod: 'mos', //m
      img: '../../../assets/alergenos/m.png',
      checked: false
    },
    {
      nombre: 'Granos de sésamo',
      cod: 'se', //gs
      img: '../../../assets/alergenos/gs.png',
      checked: false
    },
    {
      nombre: 'Altramuses',
      cod: 'al',
      img: '../../../assets/alergenos/al1.png',
      checked: false
    },
    {
      nombre: 'Crustáceos',
      cod: 'cr',
      img: '../../../assets/alergenos/c1.png',
      checked: false
    },
    {
      nombre: 'Molúscos',
      cod: 'mo',
      img: '../../../assets/alergenos/mo1.png',
      checked: false
    },
    {
      nombre: 'Sulfitos',
      cod: 'su', //gs
      img: '../../../assets/alergenos/d1.png',
      checked: false
    },

  ];
  //faltan altramuses
  constructor() { }

  ngOnInit(): void {

    if(this.alergenosFromFather){
      for (let i = 0; i < this.alergenos.length; i++) {
        if(this.alergenosFromFather[this.alergenos[i].cod] && this.alergenosFromFather[this.alergenos[i].cod] === 1){
          this.alergenos[i].checked = true;
        }
      }
    }
  }

  changeCheck(i: number){
    this.alergenos[i].checked = !this.alergenos[i].checked;
    this.alergenosEvent.emit(this.alergenos);
  }

}
