
<div class="container" *ngIf="isMobile" class="cuerpoWeb pt-3">
    <div class="text-right mb-5 mr-3">
        <app-profile-menu (apartadoEvent)="receiveApartado($event)"></app-profile-menu>
    </div>
    <div class="row justify-content-center align-items-center minh-100">
        <div class="p-4 card opacity-75">
            <h1 class="text-center mt-2">¡ Bienvenido !</h1>
            <h3 class="text-center">La opción móvil aún no está dispoinible, pero puedes gestionar tu carta más comodamente desde tu ordenador!</h3>
        </div>
    </div>
</div>

<div  *ngIf="!isMobile">
    <div class="text-right mt-3 mr-3">
        <app-profile-menu (apartadoEvent)="receiveApartado($event)"></app-profile-menu>
    </div>
    <div *ngIf="apartado === 'productos'">
        <div class="row">
            <div class="col-lg-3 col-md-4 pl-1">
                <app-tree (nodeEvent)="receiveNode($event);"></app-tree>
            </div>
            <div class="col-lg-9 col-md-8 p-5 mt-2">
                <app-categories *ngIf="selectedNode" [selectedNodeFromFather]="selectedNode" ></app-categories>
            </div>
        </div>
    </div>    
    
    <div *ngIf="apartado === 'perfil'">
        <app-profile></app-profile>
    </div>  
    <div *ngIf="apartado === 'qr-generator'">
       <app-qr-generator></app-qr-generator>
    </div>   
</div>



