import { Component, Input } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

import { NotifierService } from 'angular-notifier';
import { TablasService } from 'src/app/services/tablas.service';
import { environment } from 'src/environments/environment';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {
  @Input() selectedNodeFromFather: any;
  private readonly notifier: NotifierService;

  newProductToChild : object | undefined;

  categoria: string = '';
  selectedCategory: any;

  constructor(public dialog: MatDialog, notifierService: NotifierService, private tablasService: TablasService) { 
    this.notifier = notifierService;
  }

  panelOpenState = true;

  categories = [
    {
      nombre: '',
      editable : false,
      id_categoria : ''
    }
  ]
  
  // drop(event: CdkDragDrop<string[]>) {
  drop(event: any) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);

    for (let i = 0; i < this.categories.length; i++) {
      let data: any = this.categories[i];
      data.orden = i;
      this.updateCategoria(data); 
    }


  }

  openDialog(modal:any, category? : any) {
    if(category) this.selectedCategory = category;
    if(modal){
      const dialogRef = this.dialog.open(modal, {
        // width: '50%'
     });
     dialogRef.afterClosed().subscribe(result => {
      this.categoria = '';
     });
    }
  }
  

  addCategories(item: string){
    const id_categoria = uuidv4();
    const newCategory = {
      nombre : item,
      editable : false,
      id_categoria

    }
    this.categories.push(newCategory);
    this.dialog.closeAll();
    this.insertCategoria(id_categoria, item);
  }

  deleteCategories(category: any){
    const index = this.categories.findIndex(item  => item.nombre === category.nombre);

    this.deleteCategoria(index);
  }

  receiveNewProduct(event: object){
    this.dialog.closeAll();
    this.newProductToChild = event;
    this.notifier.notify('success', 'Nuevo producto añadido');
  }

  ngOnChanges() {
    this.getCategorias(this.selectedNodeFromFather.idItem);
  }

  editCategory(panel: any, index: number){
    this.categories[index].nombre = panel.nombre;
    this.categories[index].editable = false;
    let data: any = this.categories[index];
    data.orden = index;
    this.updateCategoria(data);
    this.notifier.notify('success', 'Se ha editado la categoría con éxito');
  }

  getCategorias(id_menu: string){
    const body = {
      'accion' : 'get',
      'tabla':'categorias',     
      'select':['*'],
      'where' :[{'campo':'id_cliente','tipo':'n','condicion':'=','valor': ''+environment.id_cliente},
                {'campo':'id_menu','tipo':'s','condicion':'=','valor': id_menu}],
      "order":[{"campo":"orden","sentido":"ASC"}]
    }

    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {

      let dataCategoria: any = [];
      if(data){
        if(data.length && data.length > 0){
          for (let i = 0; i < data.length; i++) {
            const aux = {
              nombre: data[i].nombre,
              editable : false,
              id_categoria : data[i]['id_categoria']
            };
            dataCategoria[i] = aux;
          }
        }
      }
      this.categories = dataCategoria;
    })
    .catch((error) => {
       console.error('Error al obtener datos:', error);
    });
  }

  insertCategoria(id_categoria: string, nombre: string){
    const body = {
      'accion' : 'insert',
      'tabla':'categorias',
      'insert' : [{'campo':'id_cliente','tipo':'n','valor': ''+environment.id_cliente},
                {'campo':'id_menu','tipo':'s','valor': ''+this.selectedNodeFromFather.idItem},
                {'campo':'id_categoria','tipo':'s','valor': ''+id_categoria},
                {'campo':'nombre','tipo':'s','valor': nombre},
                {'campo':'orden','tipo':'n','valor': ''+this.categories.length}
      ]
    }
    
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      if(data){
        this.notifier.notify('success', 'Nueva categoría añadida con éxito');
      }
    })
    .catch((error) => {
      console.error('Error al obtener datos:', error);
      this.notifier.notify('warning', error);
    });
  }

  deleteCategoria(index: number){
    const body = {
      'accion' : 'delete',
      'tabla':'categorias',
      'where' :[{'campo':'id_categoria','tipo':'s','condicion':'=','valor': this.categories[index].id_categoria}]
    }
    
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      if(data){
        this.categories.splice(index, 1);
        this.notifier.notify('success', 'La categoría ha sido eliminada con éxito');
      }
    })
    .catch((error) => {
      console.error('Error al obtener datos:', error);
      this.notifier.notify('warning', error);
    });
  }

  updateCategoria(categoria: any){
    const body = {
      'accion' : 'update',
      'tabla':'categorias',  
      'where' :[{'campo':'id_categoria','tipo':'s','condicion':'=','valor': ''+categoria.id_categoria}],
      'update' : [{'campo':'orden','tipo':'n','valor': ''+categoria.orden},
                  {'campo':'nombre','tipo':'s','valor': ''+categoria.nombre}]
    }
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {

    })
    .catch((error) => {
       console.error('Error al obtener datos:', error);
    });
  }

}
