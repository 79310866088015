<notifier-container></notifier-container>
<div>

  <div class="w-100">

    
      <div class="body-mobile">
        <div class="iphone-x" style="min-width: 400px;">
          <div class="contenedor">
            <div class="barra-superior">
              <!-- Botón de apagado -->
            </div>
            <div class="contenido">
              <!-- Contenido de tu aplicación móvil -->
              <div class="tu-div">
                <!-- Contenido del div que deseas agregar -->
                <app-web-mobile-screen></app-web-mobile-screen>
              </div>
            </div>
            <div class="barra-inferior">
    
            </div>
          </div>
        </div>
    
        <div class="float-right mt-2 ml-4 p-5 pt-0 w-100 row" style="background-color: white; border-radius: 10px;">
          
          <div class="col-12 row">
            <div class="col-6">
              <h2>Elige los colores de tu carta</h2>
              
              <div class="center-vertically mb-2">
      
                <div class="color-input-wrapper">
                  <input class="cursor-pointer" (change)="emitMenu()" type="color" id="background-bar" name="background-bar" [(ngModel)]="menuStyle.bgBarraSuperior" />
                </div>
                <label class="cursor-pointer ml-2" for="background-bar">Fondo de barra superior</label>
              </div>
      
              <div class="center-vertically mb-2">
                <div class="color-input-wrapper">
                  <input class="cursor-pointer" (change)="emitMenu()" type="color" id="background-menu" name="background-menu" [(ngModel)]="menuStyle.bgMenu" />
                </div>
                <label class="cursor-pointer ml-2" for="background-menu">Fondo del menú</label>
              </div>
      
              <div class="center-vertically mb-2">
                <div class="color-input-wrapper">
                  <input class="cursor-pointer" (change)="emitMenu()" type="color" id="texto1" name="texto1" [(ngModel)]="menuStyle.colorTexto1" />
                </div>
                <label class="cursor-pointer ml-2" for="texto1">Texto 1</label>
              </div>
            
              <div class="center-vertically mb-2">
                <div class="color-input-wrapper">
                  <input class="cursor-pointer" (change)="emitMenu()" type="color" id="texto2" name="texto2" [(ngModel)]="menuStyle.colorTexto2" />
                </div>
                <label class="cursor-pointer ml-2" for="texto2">Texto 2</label>
              </div>
      
              <div class="center-vertically mb-2">
                <div class="color-input-wrapper">
                  <input class="cursor-pointer" (change)="emitMenu()" type="color" id="detalles" name="detalles" [(ngModel)]="menuStyle.colorDetalles" />
                </div>
                <label class="cursor-pointer ml-2" for="detalles">Detalles</label>
              </div>
      
              
              
            </div>
            <div class="col-6">
                <h2>Fuente del texto</h2>
                <div class="container">
                  <div class="btn-group w-100">
                    <button class="btn btn-sm btn-primary w-50" (click)="onChangeFont(-1)">Atrás</button>
                    <button class="btn btn-sm btn-primary w-50" (click)="onChangeFont(1)">Siguiente</button>
                  </div>
                </div>
                <br>
                <div *ngIf="selected" class="container">
                  <div class="card text-center">  
                    <div class="card-header h3" [style.font-family]="selected.header">{{selected.header}}
                    </div>
                  </div>
                </div>
            </div>
          </div>
    
          <div class="col-12">
            <h2>Imagen de fondo</h2>
            <app-image-cropper (imageEvent)="receiveImage($event)" [formatType]="'rectangular'" ></app-image-cropper>
            <div *ngIf="showOlderBGLogo && menuStyle?.backgroundLogo">
              <img class="text-center m-auto" [src]="menuStyle?.backgroundLogo" alt="">
            </div>
          </div>
        
        </div>
    
    
        
      </div>
    

  </div>

</div>





