import { Component, OnInit, HostListener } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FirebaseService } from '../../services/firebase.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  usuario: string = '';
  password: string = '';
  login: boolean = true;
  isMobile: boolean = false;

  registroMail: string = '';
  registroPassword: string = '';
  registroPasswordConfirm: string = '';

  // *********************

  submitted = false;
  recoverPassword = false;

  private readonly notifier: NotifierService;

  constructor(private fb: FormBuilder, notifierService: NotifierService,
    public http: HttpClient, public firebaseService: FirebaseService,
    private deviceService: DeviceDetectorService){  
    this.notifier = notifierService;
  }

  loginFormAcceso = this.fb.group({
    email : ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    password : ['', [Validators.required]]
  });

  loginFormRegistro = this.fb.group({
    email : ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    password : ['', [Validators.required, Validators.minLength(8)]],
    password2 : ['', [Validators.required, Validators.minLength(8)]]
  });

  loginFormRecover = this.fb.group({
    email : ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
  });

  get f() { return this.loginFormAcceso.controls; }
  get fr() { return this.loginFormRegistro.controls; }
  get r() { return this.loginFormRegistro.controls; }

  ngOnInit(): void {
    //this.isMobile = this.deviceService.isMobile(); // se lleva a el cpanel para que pueda crear la cuenta
  }


  recoverPassMail(){
    // if(this.usuario != '' && EmailValidator.validate(this.usuario)){
    //       let body = {
    //         "accion" : "recover_url",
    //         "mail": this.usuario
    //       };
    //       const headers = {'Content-Type':  'application/json'};
    //       const ruta = `${environment.api}/v1/api/get`;
    //       this.http.post<any>(ruta, body, { headers }).subscribe((data:any) =>  {
    //         if(data.cod == 0){
    //           this.notifier.notify('success', 'Email de recuperación enviado con éxito, revice su casilla de correo');
    //         }else{
    //           this.notifier.notify('warning', data.msg);
    //         }
    //       });
    // }else{
    //   this.notifier.notify('warning', 'El mail indicado es incorrecto');
    // }
  }

  putActivo(email: string){
    let body = {
      "accion" : "put_activo",
      "email":email
    };
    const headers = {'Content-Type':  'application/json'};
    const ruta = `${environment.api}/get`;
    this.http.post<any>(ruta, body, { headers }).subscribe((data:any) =>  {
      // console.log(data);
    });
  }


  logIn(){
    this.submitted = true;
      if(this.loginFormAcceso.valid){
        const email = this.loginFormAcceso.value.email;
        const password = this.loginFormAcceso.value.password;
        this.firebaseService.Login(email || '', password || '');
      }
  }

  registrarse(){
    this.submitted = true;
    if(this.loginFormRegistro?.valid){
      const email = this.loginFormRegistro.value.email;
      const password = this.loginFormRegistro.value.password;
      const password2 = this.loginFormRegistro.value.password2;

      if(password === password2){
        this.firebaseService.Register(email || '', password || '');
        this.loginFormRegistro.reset();
        this.login = true;
      }else{
        this.notifier.notify('warning', 'Las contraseñas no coinciden');
      }
    }
  }

  recoverPasswordSubmit(){
    this.submitted = true;
    if(this.loginFormRecover?.valid){
      const email = this.loginFormRecover.value.email;
      this.firebaseService.sendPasswordResetEmails(email || '');
      this.recoverPassword = false;
    }
  }

 

}
