import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuStylesService } from 'src/app/services/menu-styles.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-qr-generator',
  templateUrl: './qr-generator.component.html',
  styleUrls: ['./qr-generator.component.scss']
})
export class QrGeneratorComponent implements OnInit {
  public qrURL: string = '';
  menuStyle: any;
  showOlderLogo: boolean = true;

  constructor(private styleMenuService: MenuStylesService) {
  }
  withImage: boolean = true;
  qrImage: string = '';
  
  ngOnInit(): void {
    this.qrURL = `https://www.hostelemetrica.es/#/carta/${Number(localStorage.getItem('id_cliente'))}`;
    this.styleMenuService.castStyleMenu.subscribe(menuStyle => this.menuStyle = menuStyle);
    if(this.menuStyle.logo){
      this.qrImage = this.menuStyle.logo; 
    }
  }

  receiveImage(event: string){
    this.showOlderLogo = false;
    this.qrImage = event;
  }

  saveAsImage() {
    let elem = document.getElementById('qr');
    if(elem){
      html2canvas(elem).then(function (canvas) {
        let generatedImage = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let a = document.createElement('a');
        a.href = generatedImage;
        a.download = `qr.png`;
        a.click();
      });
    }
    
  }

}
