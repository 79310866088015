
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div *ngIf="menu && menuStyle && menuReady" [ngStyle]="{'background-color': menuStyle.bgMenu}">
    <header class="mt-5" style="margin-bottom: 150px;">
      <div class="encabezado" [ngStyle]="{'background-image':'url('+ menuStyle.backgroundLogo +')'}">
        <div class="logo">
          <img class="imgLogo" [src]="menuStyle.logo" width="100%" style="border-radius: 50%;">
        </div>
      </div>
    </header>

    <!-- ------------------------------------------------- -->
    <!-- <p class="color-black">Locale Currency symbol is : {{sym}}</p> 
    <p class="color-black">Locale is : {{locale}}</p>  -->
    <section class="menu" *ngFor="let item of menu;" [id]="item.menu">


      <h1 class="tituloMenu" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto1}" > {{item.menu | uppercase}} </h1>

      <!-- <hr class="linea" [ngStyle]="{'border-top': '2px solid ' + menuStyle.colorDetalles}"> -->

      <!-- ------------------------------------------------- -->
      <div *ngFor="let categoria of item.categorias;">
        <div class="cuadros">
          <h2 class="titulo" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto1}" >{{categoria.categoria}}</h2>

          <div class="row" *ngFor="let producto of categoria.productos;">
            <div class="col-md-10 col-sm-12 pl-3" (click)="openDialog(itemDialog, producto);">
              <h3 class="col-sm-12 nombre" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto2}">{{producto.nombre}}</h3>
              <div class="col-sm-12 descripcion" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto2}">{{producto.descripcion}}<p class="parrafoMuerto"> </p>
                <hr class="lineaPrecio">
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="divPrecio" [ngStyle]="{'background-color': menuStyle.colorDetalles}">
                <p class="estilosPrecio" [style.font-family]="menuStyle.textFont.header" [ngStyle]="{'color': menuStyle.colorTexto2}">{{producto.precio}}€</p>
              </div>
            </div>
            <div class="col-12 m-auto text-center">
              <div class="m-auto text-center pb-3">
                <img *ngIf="producto.al === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/al1.png' alt='Altramuses'>
                <img *ngIf="producto.ap === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/a.png' alt='Apio'>
                <img *ngIf="producto.ca === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/cc.png' alt='Cacahuetes'>
                <img *ngIf="producto.ce === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/g.png' alt='Gluten'>
                <img *ngIf="producto.cr === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/c1.png' alt='Crustáceos'>
                <img *ngIf="producto.fs === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/fc.png' alt='Frutos Secos'>
                <img *ngIf="producto.hu === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/h.png' alt='Huevos'>
                <img *ngIf="producto.la === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/l.png' alt='Lacteos'>
                <img *ngIf="producto.mo === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/mo1.png' alt='Moluscos'>
                <img *ngIf="producto.mos === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/m.png' alt='Mostaza'> 
                <img *ngIf="producto.pe === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/p.png' alt='Pescado'>
                <img *ngIf="producto.se === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/gs.png' alt='Granos de Sésamo'>
                <img *ngIf="producto.so === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/s.png' alt='Soja'>
                <img *ngIf="producto.su === 1" style='width: 25px;display: inline; padding-left: 5px;' src='../../../assets/alergenos/d1.png' alt='Sulfitos'>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="linea" [ngStyle]="{'border-top': '2px solid ' + menuStyle.colorDetalles}">

      <!-- ------------------------------------------------- -->

    </section>
  </div>


<a class="buscador" (click)="openDialog(searchInMenu)">
  <svg class="svg-icon search-icon" style="width:35px;height:35px;" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g class="search-path" fill="none" stroke="#848F91"><path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4"/><circle cx="8" cy="8" r="7"/></g></svg>
</a>

<ng-template #searchInMenu>
  <div>
    <form>
      <mat-form-field class="w-100">
        <mat-label>Buscar por nombre</mat-label>
        <input matInput [(ngModel)]="nombreProducto" placeholder="Ej. Pizza" value="" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <section>
        <div class="float-right">
          <button (click)="searchInMenuService();" mat-flat-button>Cancelar</button>
        <!-- </div>
        <div class="float-right"> -->
          <button (click)="searchInMenuService(nombreProducto);" mat-flat-button>Buscar</button>
        </div>
      </section>
    </form>
  </div>
 </ng-template>



  <ng-template #itemDialog>
    <div *ngIf="selectedItem">
      <h1 [style.font-family]="menuStyle.textFont.header" mat-dialog-title>{{selectedItem.nombre}}</h1>
      <div mat-dialog-content>
        <p [style.font-family]="menuStyle.textFont.header">{{selectedItem.descripcion}}</p>

        <div class="contenedor_img">
            <img class="producto_img"  ngSrc="{{hostDir + selectedItem.imagen}}" style="position: relative;object-fit: contain;max-width: 300px;max-height: 300px;" 
                fill *ngIf="selectedItem.imagen" [alt]="selectedItem.selectedItem">
        </div>

        <div class="m-auto">
            <div *ngFor="let alergeno of alergenos; let i = index">
                    <div *ngIf="selectedItem[alergeno['cod']] === 1" class="m-auto" style="text-align: center;">
                        <img class="producto_img m-auto"  ngSrc="{{alergeno.img}}" width="30" height="30" *ngIf="alergeno.img"[alt]="alergeno.nombre">
                        <p [style.font-family]="menuStyle.textFont.header">{{alergeno.nombre}}</p>
                    </div>
            </div>
    
        </div>

      </div>
    </div>
  </ng-template>

