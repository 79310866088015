
<div class="m-auto text-center containter">

    <div class="row">
        <div class="col-12">
            <button download="qrcode" (click)="saveAsImage()" mat-raised-button color="primary">Descargar QR</button>
        </div>

        <div class="col-6 m-auto pt-5 mt-2" style="align-items: center;"> 

            <mat-slide-toggle [(ngModel)]="withImage" >Con imágen</mat-slide-toggle>
            <div class="qrcodeImage">
                <qrcode
                    class="centerQR"
                    id='qr'
                    [qrdata]="qrURL" 
                    [width]="600" 
                    [imageSrc]="withImage ? qrImage : ''" 
                    [imageHeight]="125"
                    [imageWidth]="125"    
                    [cssClass]="'demoBorderRadius'"
                    [allowEmptyString]="true"
                    [ariaLabel]="'QR Code image with the following content...'"
                    [cssClass]="'center centerQR'"
                    [colorDark]="'#000000ff'"
                    [colorLight]="'#ffffffff'"
                    [elementType]="'canvas'"
                    [errorCorrectionLevel]="'M'"
                    [margin]="1"
                    [scale]="1"
                    [title]="'QR Hostelemétrica'"
                ></qrcode>
            </div>
        </div>

        <div class="col-6 pt-3">
            <ng-container *ngIf="withImage">
                <h2 class="pt-5">Imágen dentro del QR</h2>
                <app-image-cropper (imageEvent)="receiveImage($event)" [formatType]="'square'"></app-image-cropper>
                <div *ngIf="showOlderLogo && menuStyle?.logo">
                  <img class="text-center m-auto" style="max-width: 500px; max-height: 500px;" [src]="menuStyle?.logo" alt="">
                </div>
            </ng-container>
        </div>

        <div class="col-12 text">
          <label>
            {{qrURL}}
          </label>
        </div>

    </div>
    
    

    

</div>
