import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuStylesService } from 'src/app/services/menu-styles.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cpanel',
  templateUrl: './cpanel.component.html',
  styleUrls: ['./cpanel.component.scss']
})
export class CpanelComponent implements OnInit {
  selectedNode: object | undefined;
  apartado: string = 'productos';
  ready: boolean = false;
  isMobile: boolean = false;
  constructor(private deviceService: DeviceDetectorService, private menuStyleService: MenuStylesService) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    const token = localStorage.getItem('user')
    const user = JSON.parse(token as string);
    environment.email = user.email;
    environment.id_cliente = Number(localStorage.getItem('id_cliente'));
    if(environment.email && environment.id_cliente){
      this.menuStyleService.getConfigCarta();
      this.ready = true;
    }
  }

  receiveNode(event: object){
    this.selectedNode = event;
  }

  receiveApartado(event: string){
    this.apartado = event;
  }

}
