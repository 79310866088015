import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
  
  @Output() editedProductEvent = new EventEmitter<Object>();
  @Input() newProductToChild: any; // object | undefined;
  hostDir = `${environment.host}/`;
  imageError: string = '../../../assets/imagenes/imagen_no_disponible.png';
  srcResult: String = '';
  showOriginalImage: Boolean = true;
  alergenos: any;

  firstFormGroup = this._formBuilder.group({
    nombre: ['', Validators.required],
    codigo: [''],
    descripcion: [''],
    precio: [''],
    stock: [''],
  });

  secondFormGroup = this._formBuilder.group({
    imagen: ['', Validators.required],
  });


  changes: number = 0;

  isLinear = false;
  imageBase64: string = '';
 
  constructor(private _formBuilder: UntypedFormBuilder,public dialog: MatDialog) {
    this.firstFormGroup.valueChanges.subscribe((data:any) => {
      this.changes ++;
      // You can perform actions here based on the form value changes
    });
  }

  ngOnInit(): void {
    const newValuesFG = {
      nombre: this.newProductToChild.nombre,
      codigo: this.newProductToChild.codigo,
      descripcion: this.newProductToChild.descripcion,
      precio: this.newProductToChild.precio,
      stock: this.newProductToChild.stock
    };
    this.firstFormGroup.setValue(newValuesFG);

    if(this.newProductToChild.imagen){
      const newValuesFG2 = {
        imagen: this.newProductToChild.imagen,
      };
      this.firstFormGroup.setValue(newValuesFG2);
    }
    
  }

  openDialog(modal: any) {
    if(modal){
      const dialogRef = this.dialog.open(modal, {
        // width: '50%'
     });
     dialogRef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
     });
    }
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
  
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };
  
      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }
  

  editProduct(){
    //id
    // nombre
    // codigo
    // descripcion
    // precio
    // stock

    let  newProduct: any = {
      id : this.newProductToChild.id,
      nombre : this.firstFormGroup.controls['nombre'].value,
      codigo : this.firstFormGroup.controls['codigo'].value,
      descripcion : this.firstFormGroup.controls['descripcion'].value,
      precio : this.firstFormGroup.controls['precio'].value,
      stock : this.firstFormGroup.controls['stock'].value,
    }
    if(this.imageBase64 !== '') newProduct['imagen'] = this.imageBase64;
    if(this.alergenos){
      newProduct = this.generateAlergenoList(newProduct, this.alergenos);
    }
    this.editedProductEvent.emit(newProduct);
  }

  receiveImage($event: string){
    this.imageBase64 = $event;
    this.showOriginalImage = false;
    this.changes ++;
  }

  receiveAlergenos($event: any){
    this.alergenos = $event;
    this.changes ++;
  }

  generateAlergenoList = (product: any, arrayAlergenos: any) => {
    if(arrayAlergenos){
      arrayAlergenos.forEach((element: any) => {
        if(element.checked){
          product[element.cod] = 1;
        }
      });
    }
    return product;
  }


}
