import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }


  getToken(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const urlaux = `${environment.api}/login`;
      const headers = {'Content-Type':  'application/json'};

      this.http.post<any>(urlaux, body , { headers }).subscribe((data) => {
        if(data.cod === 0){
          const base64String = btoa(data.token);
          localStorage.setItem('token', base64String);
          localStorage.setItem('id_cliente', data.id_cliente);
          environment.id_cliente = data.id_cliente;
          resolve(data);
        }
      },
      (error) => {
          resolve(error);
      });
    });
  }

  registrarse(user : any){
    const body = {
      accion : "put_user",
      mail : user.email,
      uid : user.uid,
    }
    const headers = {'Content-Type':  'application/json'};
    const ruta = `${environment.api}/get`;
    this.http.post<any>(ruta, body, { headers }).subscribe((data:any) =>  {
      // console.log(data);
    });
  }

}
