<notifier-container></notifier-container>

<div class="auth-wrapper d-flex no-block justify-content-center align-items-center"
[ngClass]="isMobile ? 'cuerpoMobile' : 'cuerpoWeb'">
    <div class="auth-box m-3 m-md-0 animated fadeIn">
        <div>
            <div style="min-width: 400px;">
                <div class="row">
                    <div class="col-sm-12" >
                        <div class="md-float-material form-material">
                            <div class="auth-box card" >
                                <div class="line-separator"></div>
                                <div class="card-block animated fadeIn" *ngIf="mode === 'resetPassword'">
                                    <form [formGroup]="loginFormRegistro" novalidate (ngSubmit)="recoverPassword()">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h3 class="text-center heading">Genera tu nueva contraseña</h3>
                                            </div>
                                        </div>
    
                                        <!-- <div class="mt-3 form-group form-primary">
                                            <input type="text" class="form-control" formControlName="email"  placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && fr.email.errors }">
                                            <div *ngIf="submitted && fr.email.errors" class="invalid-feedback">
                                                <div *ngIf="fr.email.errors.required">El email es requerido</div>
                                                <div *ngIf="fr.email.errors.email">Debe ingresar un email válido</div>
                                            </div>
                                        </div> -->
    
                                        <div class="form-group form-primary">
                                            <input type="password" class="form-control" formControlName="password" placeholder="Contraseña"
                                                [ngClass]="{ 'is-invalid': submitted && fr.password.errors }">
                                            <div *ngIf="submitted && fr.password.errors" class="invalid-feedback">
                                                <div *ngIf="fr.password.errors.required">La contraseña es requerida</div>
                                                <div *ngIf="fr.password.errors.minlength">La contraseña debe tener mínimo 8 carcateres</div>
                                            </div>
                                        </div>
                                        <div class="form-group form-primary">
                                            <input type="password" class="form-control" formControlName="password2" placeholder="Repite contraseña"
                                                [ngClass]="{ 'is-invalid': submitted && fr.password2.errors }">
                                            <div *ngIf="submitted && fr.password2.errors" class="invalid-feedback">
                                                <div *ngIf="fr.password2.errors.required">La contraseña es requerida</div>
                                                <div *ngIf="fr.password2.errors.minlength">La contraseña debe tener mínimo 8 carcateres</div>
                                            </div>
                                        </div>
    
                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="submit" 
                                                    class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">
                                                    Generar contraseña
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    
                                </div>
                                <div class="card-block animated fadeIn" *ngIf="mode === 'verifyEmail'">
                                    <div class="row">
                                        <div class="col-md-12 mt-2">
                                            <button (click)="validaEmail()"
                                                class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">
                                                VALIDAR CUENTA EN HOSTELEMETRICA
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
