<div style="width: 90%" class="m-auto">
    <div style="text-align: right;">
      <h1>{{menuStyle?.enableToEditMenu}}</h1>
        <button mat-button (click)="openDialog(saveMenuConfirmation)">
            <mat-icon class="d-inline">save</mat-icon><p class="pt-2 d-inline"> GUARDAR</p>
        </button>
    </div>
    <mat-horizontal-stepper orientation="horizontal" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup" class="row mt-5">
          <ng-template matStepLabel>Datos del comercio</ng-template>

          <div class="col-6">
            <mat-form-field class="form-field width-input-l">
              <mat-label>Nombre del comercio</mat-label>
              <input matInput (change)="getFirstFormGroupValues();" placeholder="Nombre del comercio" formControlName="nombre" required>
            </mat-form-field><br>
            <mat-form-field class="form-field width-input-l">
              <mat-label>Dirección del comercio</mat-label>
              <input matInput (change)="getFirstFormGroupValues();" placeholder="Dirección del comercio" formControlName="direccion">
            </mat-form-field><br>
  
            <mat-form-field>
              <mat-label>Provincia</mat-label>
              <input matInput (change)="getFirstFormGroupValues();" placeholder="Provincia" formControlName="provincia">
            </mat-form-field>
            <mat-form-field class="ml-3">
              <mat-label>Codigo Postal</mat-label>
              <input type="number" min="0" matInput (change)="getFirstFormGroupValues();" placeholder="Codigo Postal" formControlName="cp">
            </mat-form-field><br>
  
            <mat-form-field class="form-field width-input-l">
              <mat-label>Horarios de apertura del comercio</mat-label>
              <textarea matInput rows="4" (change)="getFirstFormGroupValues();" placeholder="Horarios de apertura del comercio" formControlName="apertura"></textarea>
            </mat-form-field><br>
          </div>


          <div class="col-6">
            <mat-form-field class="form-field width-input-l">
              <mat-label>URL de Instagram</mat-label>
              <input matInput (change)="getFirstFormGroupValues();" placeholder="Dirección URL de Instagram" formControlName="instagram">
            </mat-form-field><br>
            <mat-form-field class="form-field width-input-l">
              <mat-label>URL de Facebook</mat-label>
              <input matInput (change)="getFirstFormGroupValues();" placeholder="Dirección URL de Facebook" formControlName="facebook">
            </mat-form-field><br>
          </div>
          
          
        </form>
        <div class="float-right mt-2">
          <button mat-button matStepperNext>Siguiente</button>
        </div>
      </mat-step><mat-slide-toggle (click)="$event.stopPropagation()" class="float-left pt-2 pl-2">Visible</mat-slide-toggle>
      <mat-step [stepControl]="secondFormGroup">
        <form>
          <ng-template matStepLabel>Logo</ng-template>
            <div class="mt-3">
              <mat-dialog-content>
                <div class="col-12">
                  <h2>Imagen de logo</h2>
                  <app-image-cropper (imageEvent)="receiveImage($event)" [formatType]="'square'"></app-image-cropper>
                  <div *ngIf="showOlderLogo && menuStyle?.logo">
                    <img class="text-center m-auto" style="max-width: 500px; max-height: 500px;" [src]="menuStyle?.logo" alt="">
                  </div>
                </div>
              </mat-dialog-content>
            </div>
            <div class="float-right mt-2">
                <button mat-button matStepperPrevious>Atrás</button>
                <button mat-button matStepperNext>Siguiente</button>
            </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Estilo del menú</ng-template>
        <app-mobile-menu-styler></app-mobile-menu-styler>
        <div class="float-right">
          <button mat-button matStepperPrevious>Atrás</button>
      </div>
      </mat-step>
    </mat-horizontal-stepper>
      
</div>


<ng-template #saveMenuConfirmation>
  <div *ngIf="true">
    <h1 mat-dialog-title>Guardar configuración del perfil</h1>
    <div mat-dialog-content>
      Realmente desea guardar la configuración del perfil?
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button mat-dialog-close>Cancelar</button>
      <button mat-button mat-dialog-close cdkFocusInitial  (click)="saveMenu();">Aceptar</button>
    </div>
  </div>
</ng-template>