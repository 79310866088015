import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

    
    @Output() imageEvent = new EventEmitter<string>();
    @Input() formatType: string | undefined;
    
    maxPxImage: string = 'max-width: 400px;';
    fixedArea: boolean = false;
    formatArea: number = 3 / 3;

    constructor() { }


    ngOnInit(): void {

        switch (this.formatType) {
            case 'square':
                this.fixedArea = true;
                this.formatArea = 3 / 3;
                this.maxPxImage = 'max-width: 600px;';
                break;

            case 'rectangular':
                this.fixedArea = true;
                this.formatArea = 4 / 2.5;
                this.maxPxImage = 'max-width: 600px;';
                break;

            default:
                this.fixedArea = false;
                this.formatArea = 3 / 3;
                break;
        }

    }



    imageChangedEvent: any = '';
    croppedImage: any = '';
    
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        if(!this.formatType && event.width < 400){
            this.maxPxImage = `max-width: ${event.width}px;`
        }
        this.croppedImage = event.base64;
        this.imageEvent.emit(this.croppedImage);
    }
    imageLoaded() {
       // image loaded
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

}
