import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/services/font.service';
import { MenuStylesService } from 'src/app/services/menu-styles.service';

@Component({
  selector: 'app-web-mobile-screen',
  templateUrl: './web-mobile-screen.component.html',
  styleUrls: ['./web-mobile-screen.component.scss']
})
export class WebMobileScreenComponent implements OnInit {

  menuStyle: any;
  
  JSON_menu: any = [
    {
      id : 'id-entrantes',
      categoria : 'entrantes',
      orden : 1,
      submenu : []
    },
    {
      id : 'id-principal',
      categoria : 'principal',
      orden : 2,
      submenu : []
    },
    {
      id : 'id-postres',
      categoria : 'postres',
      orden : 3,
      submenu : []
    },
    {
      id : 'id-bebidas',
      categoria : 'bebidas',
      orden : 4,
      submenu : []
    }
  ];


  constructor(private fontService: FontService, private styleMenuService: MenuStylesService) { }

  ngOnInit(): void {
    this.styleMenuService.castStyleMenu.subscribe(menuStyle => this.menuStyle = menuStyle);
    this.fontService.loadFont(this.menuStyle.textFont);
  }

}
