<!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
    {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
  </button> -->

<div style="width: 800px">

    <!-- nombre
    codigo
    descripcion
    precio
    stock -->

    <mat-vertical-stepper orientation="vertical" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Datos del producto</ng-template>
          <mat-form-field class="form-field">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="nombre" required>
          </mat-form-field><br>
          <mat-form-field class="form-field">
            <mat-label>Código</mat-label>
            <input matInput placeholder="Código" formControlName="codigo">
          </mat-form-field><br>
          <mat-form-field class="form-field">
            <mat-label>Descripción</mat-label>
            <textarea matInput rows="4" placeholder="Ej. medidas" formControlName="descripcion"></textarea>
          </mat-form-field><br>
          <mat-form-field>
            <mat-label>Precio</mat-label>
            <input type="number" min="0" value="0" step="0.1" matInput placeholder="$" formControlName="precio">
          </mat-form-field>
          <mat-form-field class="ml-3">
            <mat-label>Stock</mat-label>
            <input type="number" min="0" value="0" step="any" matInput placeholder="Cantidad" formControlName="stock">
          </mat-form-field><br>
          <div class="float-right mt-2">
            <button mat-button matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step><mat-slide-toggle (click)="$event.stopPropagation()" class="float-left pt-2 pl-2">Visible</mat-slide-toggle>
      <mat-step [stepControl]="secondFormGroup">
        <!-- <form [formGroup]="secondFormGroup"> -->
        <form>
          <ng-template matStepLabel>Imagen</ng-template>
          <!-- <mat-form-field> -->
            <!-- <mat-label>Imagen</mat-label> -->
            <!-- <input style="visibility: hidden;" matInput required> -->
            <div class="mt-3">
              <mat-dialog-content class="border-red-100">
                <app-image-cropper (imageEvent)="receiveImage($event)" ></app-image-cropper>
              </mat-dialog-content>
            </div>
                    <!-- <button type="button" mat-raised-button (click)="fileInput.click()">Elejir archivo</button>
                    <input formControlName="secondCtrl" hidden (change)="onFileSelected()" #fileInput type="file" id="file" required> -->
          <!-- </mat-form-field> -->
          <div class="float-right">
            <button mat-button matStepperPrevious>Atrás</button>
            <button mat-button matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Alergenos</ng-template>
        <app-alergenos (alergenosEvent)="receiveAlergenos($event)"></app-alergenos>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Agregar producto a la lista</ng-template>
        <p>¿Deseas agregar el nuevo producto?</p>
        <div class="float-right">
          <button mat-button matStepperPrevious>Atrás</button>
          <button mat-button (click)="addProduct();">OK</button>
          <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
  
        </div>
      </mat-step>
    </mat-vertical-stepper>

</div>