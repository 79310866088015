<div>
    <div class="d-flex justify-content-center">
        <input type="file" accept="image/png, image/jpg, image/jpeg" (change)="fileChangeEvent($event)" />
    </div>
    <div class="d-flex justify-content-center">
      <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="fixedArea" 
      [aspectRatio]="formatArea"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [style]="maxPxImage"
    ></image-cropper>
    </div>
</div>

<!-- maintainAspectRatio poner en true si se busca que la imagen sea cuadrada -->