import { Injectable,NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  Auth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  User,
  applyActionCode,
  confirmPasswordReset
} from '@angular/fire/auth';
import { NotifierService } from 'angular-notifier';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private readonly notifier: NotifierService;
  UserData : any;
  constructor(private auth: Auth, private router : Router, public ngZone: NgZone,
    private loginService: LoginService,notifierService: NotifierService){
    this.notifier = notifierService;
    onAuthStateChanged(this.auth,(user: any)=>{
      if(user){
        this.UserData = user;
        localStorage.setItem('user', JSON.stringify(this.UserData));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    })
   }

  //get User
    //get Authenticated user from firebase
    getAuthFire(){
      return this.auth.currentUser;
    }


    //get Authenticated user from Local Storage
    getAuthLocal(){
      const token = localStorage.getItem('user')
      const user = JSON.parse(token as string);
      return user;
    }


    //Check wither User Is looged in or not
    get isLoggedIn(): boolean {
      const token = localStorage.getItem('user')
      const user = JSON.parse(token as string);
      return user !== null ? true : false;
    }


    //Register Method
    Register(email : string, password : string) {
      return createUserWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        this.UserData = result.user;
        this.ngZone.run(() => {
           /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
          this.sendEmailVerification()
          // this.router.navigate(['/dashboard']);
        });
      })
      .catch((err) => {
        this.notifier.notify('error', this.errorMessage(err.message));
      });
    }


    //Login Method
    Login(email : string, password : string){
 
      return signInWithEmailAndPassword(this.auth, email, password)
      .then((result: any) => {
        this.UserData = result.user;
        if(this.UserData.emailVerified){
          const body = {
            usuario: this.UserData.email,
            uid: this.UserData.uid
          }
          this.loginService.getToken(body).then((data: any) => {
            const base64String = btoa(data.token);
            localStorage.setItem('token', base64String);
            localStorage.setItem('id_cliente', data.id_cliente);
            this.router.navigate(['cpanel']);
          })
          .catch((err: any) => {
            this.notifier.notify('error', this.errorMessage(err.message));
          });
          this.notifier.notify('success', 'Ingresó correctamente');
          this.ngZone.run(() => {
            this.router.navigate(['/cpanel']);
          });
          
        }else{
          this.notifier.notify('warning', 'Para ingresar primero debe verificar su cuenta - Revise su casilla de correo');
        }
      })
      .catch((err) => {
        this.notifier.notify('error', this.errorMessage(err.message));
      });
    }

 
   //Logout
    Logout() {
      signOut(this.auth).then(( resp )=>{
        localStorage.removeItem('token');
        localStorage.removeItem('id_cliente');
        this.router.navigate(['']);
      })
    }

    LogoutFromValidations() {
      signOut(this.auth).then(( resp )=>{
        localStorage.removeItem('token');
        localStorage.removeItem('id_cliente');
      })
    }


    //login with Email or Facebook
    //Login with Google
    GoogleAuth() {
      return this.loginWithPopup(new GoogleAuthProvider());
    }



    //Login with Facebook
    //FacebookAuth() {
    //  return this.loginWithPopup(new FacebookAuthProvider());
    //}



    //Pop Up Provider
    loginWithPopup(provider :any) {
      return signInWithPopup(this.auth,provider).then(() => {
        
        this.loginService.registrarse(this.auth.currentUser);
        
        const body = {
          usuario: this.auth.currentUser?.email,
          uid: this.UserData.uid
        }
        this.loginService.getToken(body).then((data: any) => {
          const base64String = btoa(data.token);
          localStorage.setItem('token', base64String);
          localStorage.setItem('id_cliente', data.id_cliente);
          this.notifier.notify('success', 'Ingresó correctamente');
          this.router.navigate(['cpanel']);
        })
        .catch((err: any) => {
          this.notifier.notify('error', this.errorMessage(err.message));
        });
      }).catch((err) => {
        this.notifier.notify('error', this.errorMessage(err.message));
      });
    }

    //Send Password Reset Email
    async sendPasswordResetEmails(email : string){
       sendPasswordResetEmail(this.auth,email)
       .then(() => {  
          this.notifier.notify('success', 'Email de recuperacion enviado con éxito');
       })
       .catch((err) => {
          this.notifier.notify('error', this.errorMessage(err.message));
      });
    }

    //Send Email Verification
    sendEmailVerification(){
      this.loginService.registrarse(this.auth.currentUser);
      this.notifier.notify('success', 'Se ha enviado un email de verificación de cuenta - Por favor revise su casilla de correo');
      this.router.navigate(['']);
      return sendEmailVerification(this.auth.currentUser as User );
    }



    handleVerifyEmail(oobCode: string) {
      applyActionCode(this.auth, oobCode).then((resp:any) => {
        this.notifier.notify('success', 'Verificación exitosa');
        setTimeout(() => {
          this.Logout();
        }, 600);
      }).catch((err:any) => {
        this.notifier.notify('error', this.errorMessage(err.message));
      });
    }

    resetPassword(oobCode: string, password: string){
      confirmPasswordReset(this.auth, oobCode, password).
      then(() => {
        this.notifier.notify('success', 'Contraseña generada con éxito');
        setTimeout(() => {
          this.router.navigate(['']);
        }, 600);
      })
      .catch(err => {
        this.notifier.notify('error', this.errorMessage(err.message));
      });

    }


    errorMessage = (message:string) => {
      let msg: string = '';
      switch (message) {
        case 'Firebase: Error (auth/invalid-action-code).':
          msg = 'El link de cambio de contraseña ha expirado, por favor inicie el proceso otra vez'
          break;

        case 'Firebase: Error (auth/wrong-password).':
          msg = 'La contraseña ingresada es incorrecta'
          break;

        case 'Firebase: Error (auth/user-not-found).':
          msg = 'El usuario ingresado no existe'
          break;

        case 'Firebase: Error (auth/email-already-in-use).':
          msg = 'El email ingresado ya está en uso'
          break;
      
        default:
          msg = message;
          break;
      }

      return msg;
    }


}
