import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { LoginService } from 'src/app/services/login.service';
import { TablasService } from 'src/app/services/tablas.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  
  error: string | null = null;

  constructor(private tablasService: TablasService, private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {

    if(this.tablasService.getBearer() !== ''){
      this.router.navigate(['/cpanel']);
    }
  }

  form: UntypedFormGroup = new UntypedFormGroup({
    usuario: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  });

  submit() {
    if(this.form.valid) {
      this.doLogin(this.form.value);
    }
  }

  doLogin(body: any){
    this.loginService.getToken(body).then((data) => {
      if(data.cod === 1){
        this.error = data.mensaje;
      }else if(data.cod === 0){
        const base64String = btoa(data.token);
        localStorage.setItem('token', base64String);
        this.router.navigate(['/cpanel']);
      }
    })
    .catch((error) => {
       console.error('Error haciendo login:', error);
    });
  }


}
