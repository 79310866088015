import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from '@angular/fire/auth';
import { FirebaseService } from 'src/app/services/firebase.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {
  oobCode?: Auth;
  submitted: boolean = false;
  isMobile: boolean = false;
  private readonly notifier: NotifierService;

  
  constructor(private rutaActiva: ActivatedRoute, private firebaseService: FirebaseService,
    private fb: FormBuilder, notifierService: NotifierService) { 
      this.notifier = notifierService;
    }

  mode: string = '';
  oobCodeReceived: string = '';

  loginFormRegistro = this.fb.group({
    password : ['', [Validators.required, Validators.minLength(8)]],
    password2 : ['', [Validators.required, Validators.minLength(8)]]
  });
  get fr() { return this.loginFormRegistro.controls; }

  ngOnInit(): void {
    this.firebaseService.LogoutFromValidations();
    this.rutaActiva.queryParams
    .subscribe(params => {
        this.oobCodeReceived = params.oobCode;
        this.mode = params.mode;
      }
    );
  }


  recoverPassword(){
    this.submitted = true;
    if(this.loginFormRegistro?.valid){
      const password = this.loginFormRegistro.value.password;
      const password2 = this.loginFormRegistro.value.password2;
      if(password === password2){
        this.firebaseService.resetPassword(this.oobCodeReceived || '', password || '');
      }else{
        this.notifier.notify('warning', 'Las contraseñas no coinciden');
      }
    }
  }

  validaEmail(){
    this.firebaseService.handleVerifyEmail(this.oobCodeReceived);
  }

}
