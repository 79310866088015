import { Component, OnInit } from '@angular/core';
import { FontService } from '../../services/font.service';
import { MenuStylesService } from 'src/app/services/menu-styles.service';

@Component({
  selector: 'app-mobile-menu-styler',
  templateUrl: './mobile-menu-styler.component.html',
  styleUrls: ['./mobile-menu-styler.component.scss']
})
export class MobileMenuStylerComponent implements OnInit {

  apiKeyGoogleFonts: string = 'AIzaSyDcGiIIUjpreSh9fSW7c9RwkGP9LLUIOes';
  showOlderBGLogo = true;
  
  pairs:any = [
    { header: 'Oswald', body: 'EB Garamond'},
    { header: 'Oswald',  body: 'Cardo'},
    { header: 'Open Sans', body: 'Open Sans Condensed'},
    { header: 'Raleway', body: 'Roboto Slab'},
    { header: 'Roboto', body: 'Roboto Condensed'},
    { header: 'Playfair Display', body: 'Source Sans Pro'},
    { header: 'Playfair Display', body: 'Chivo'},
    { header: 'Alegreya' , body: 'Lato'},   
    { header: 'Fjalla One' , body: 'Noto Sans'},
    { header: 'PT Sans', body: 'PT Sans Narrow' },
    { header: 'Josefin Sans', body: 'Amatic SC'},
    { header: 'Merriweather', body: 'Montserrat'},
    { header: 'Quicksand', hstyle:'medium',  body: 'Quickand', bstyle:'light'},
    { header: 'Archivo Black',  body: 'Judson'},
    { header: 'Crimson Text',  body: 'Work Sans'},
    { header: 'Abril Fatface', body: 'Roboto', bstyle: 'light'},
    { header: 'Archivo', hstyle:'medium', body: 'Open Sans', bstyle:'light'},
    { header: 'Montserrat', body:'Work Sans' },
    { header: 'Montserrat', body:'Lora' },
    { header: 'Prata', body:'Lato'},
  ];

  selected: any;
  menuStyle: any;
  idx = 0;

  constructor(private fontService: FontService, private styleMenuService: MenuStylesService) {
    this.setPair();
  }

  setPair() {
    this.selected = this.pairs[this.idx];
    this.fontService.loadFont(this.selected);
  }
  
  onChangeFont(n:number) {
    this.idx
    if(this.idx + n < 0) {
      this.idx = this.pairs.length - 1;
    } else if(this.idx + n > this.pairs.length - 1) {
      this.idx = 0;
    } else {
      this.idx += n;
    }
    this.setPair();
    this.menuStyle.textFont = this.selected;
    this.emitMenu();
  }

  ngOnInit(): void {
    this.styleMenuService.castStyleMenu.subscribe(menuStyle => this.menuStyle = menuStyle);
    this.idx = this.pairs.indexOf(this.pairs.find((item:any) => item.header === this.menuStyle.textFont.header));
    this.onChangeFont(0);
  }

  receiveImage(event: string){
    this.menuStyle.backgroundLogo = event;
    this.showOlderBGLogo = false;
    this.emitMenu();
  }

  emitMenu(){
    this.styleMenuService.editMenu(this.menuStyle);
  }

}
